<template>
  <div class="service">
    <div class="pageTtlBlock">
      <h1 class="pageTtlBlock__ttl">
        SERVICE
        <span>お役立ちツール</span>
      </h1>
    </div>
    <div class="inner">
      <section class="service__outline js-scroll">
        <h3 class="service__outline__ttl">
          照明メーカーだからできる、検査環境コンサルティング。
          <br />最適な光環境で目視検査の課題を解決
        </h3>
        <p class="service__outline__txt">
         検査工程を新設・更新するにあたって選定される照明器具。用途・目的に合った最適なスペックで選定されていますか？一概に検査照明と言っても、個々の特徴や解決のできる課題は様々です。
         <br>弊社では、照明のプロフェッショナルによる調査によって検査現場の光環境の状態を見える化し、最善策を提案します。弊社が行っているサービスをご紹介いたします。ぜひご活用ください。
        </p>
      </section>
      <div class="service__contents">
        <div class="service__contents__block js-scroll">
          <div class="service__contents__block__txtBlock">
            <p class="service__contents__block__ttl">
              照度シミュレーション分析(無料)
            </p>
            <p class="service__contents__block__txt">
             弊社では、検査に最適な照明環境をシミュレートします。照明機器の配光特性の測定およびIESファイルの作成・編集〜照度シミュレーションまで、全て社内で一貫して行うことが可能です。下記お問い合わせフォームに寸法を入力していただければ、無料にて照度シミュレーションをご提供いたします。
            </p>
            <router-link class="service__contents__block__btn btn-or" :to="{ name: 'ContactSimulation'}">
             <span>
             照度シミュレーションお問い合わせ
             </span>
            </router-link>
          </div>
          <p class="service__contents__block__img" />
        </div>
        <div class="service__contents__block js-scroll">
          <div class="service__contents__block__txtBlock">
            <p class="service__contents__block__ttl">
              サンプルのお貸し出し（無料）
            </p>
            <p class="service__contents__block__txt">
             お客様にLuci製品を導入してよかったとご満足していただくため、事前の現場検証にサンプルの貸し出しを無料で行っております。実際の検査現場での照明の価値を体感し、環境改善のきっかけにぜひご利用ください。本サイト内の問合せフォームにて、ご希望の商品の型番、送付先をご記入の上、ご依頼ください。
             <span class="note">
              ※サンプル用の灯具のお貸し出しとなるため、ご希望のサイズがない場合もございます。ご了承ください。
              </span>
            </p>
            <router-link class="service__contents__block__btn btn-or" :to="{ name: 'Contact'}">
             <span>
              サンプルお問い合わせ
              </span>
            </router-link>
          </div>
          <p class="service__contents__block__img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import scrollAnimMixin from "../../mixins/scrollAnimMixin.js";

export default {
  mixins: [scrollAnimMixin],
  head: {
  title: {
    inner: 'お役立ちツール'
  },
  meta: [
    { property: 'og:title', content: 'お役立ちツール｜GENBA SHIKO' },
    { name: 'description', content: '経験豊かなスタッフが、お客様の自動車工場の製造現場における検査環境の光の状況を調査し、照度シミュレーション分析やサンプルを用いた最善策を無料で提案いたします。' },
    { property: 'og:description', content: '経験豊かなスタッフが、お客様の自動車工場の製造現場における検査環境の光の状況を調査し、照度シミュレーション分析やサンプルを用いた最善策を無料で提案いたします。' },
    { property: 'og:url', content: 'https://luci.co.jp/genbashiko/service' },
    { property: 'og:type', content: 'article' }
    ]
  }
};
</script>
<style lang="scss" scoped>
.service {
  .pageTtlBlock {
    &::after {
      background: url("/genbashiko/img/service/bg.jpg") center/cover no-repeat;
    }
  }
  &__outline {
    margin-top: 60px;
    &__ttl {
      text-align: left;
      font-size: 3rem;
      letter-spacing: 0.2em;
    }
    &__txt {
      margin-top: 60px;
      line-height: 2.1;
      letter-spacing: 0.05em;
    }
  }
  &__contents {
    margin-top: 100px;
    &__block {
      display: flex;
      &:nth-of-type(2n) {
        flex-direction: row-reverse;
      }
      &:nth-of-type(1){
          height: 374px;
          .service__contents__block__btn{
           width: 309px;
          }
        }
        &:nth-of-type(2){ 
         height: 427px;
         .service__contents__block__btn{
           width: 225px;
          }
        }
      &__txtBlock {
        background: #f2f0ec;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__img {
        position: relative;
        flex: 0 0 620px;
        max-width: 620px;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("/genbashiko/img/service/image01-pc.jpg") center/cover no-repeat;
        }
      }
      &__ttl {
        font-size: 2.1rem;
        letter-spacing: 0.2em;
        text-align: center;
        line-height: 1;
      }
      &__txt {
        line-height: 2.1;
        margin-top: 30px;
        letter-spacing: 0.05em;
        .note{
         font-size: 1.2rem;
         display: block;
         text-indent: -1em;
         margin-left: 1em;
       }
      }
      &__btn.btn-or{
       margin: 30px auto 0;
       font-size: 1.4rem;
       padding: 15px 28px;
       span{
        letter-spacing: -.01em;
        &::before{
         right: 30px;
         width: 20px;
        }
        &::after{
         right: 28px;
        }
       }
      }
      + .service__contents__block {
        margin-top: 50px;
      }
      &:nth-of-type(2) {
        .service__contents__block__img::after {
          background: url("/genbashiko/img/service/image02-pc.jpg") center/cover no-repeat;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
   .service {
    .pageTtlBlock {
      &::after {
        background: url("/genbashiko/img/service/bg-sp.jpg") center/cover no-repeat;
      }
    }
    &__outline {
      margin-top: 40px;
      &__ttl {
        font-size: 1.5rem;
      }
      &__txt {
        margin-top: 25px;
        font-size: 1.2rem;
      }
    }
    &__contents {
      margin-top: 50px;
      &__block {
        flex-direction: column-reverse;
        height: auto;
        &:nth-of-type(2n) {
          flex-direction: column-reverse;
        }
        &:nth-of-type(1) {
         height: auto;
         .service__contents__block__img::after {
           background: url("/genbashiko/img/service/image01-sp.jpg") center/cover no-repeat;
         }
         .service__contents__block__btn{
           width: 291px;
          }
        }
        &:nth-of-type(2) {
         height: auto;
         .service__contents__block__img::after {
           background: url("/genbashiko/img/service/image02-pc.jpg") center/cover no-repeat;
         }
         .service__contents__block__btn{
           width: 205px;
          }
        }
        &__img {
          flex: 0 0 100%;
          height: 0;
          padding-bottom: 56.25%;
          max-width: 100%;
        }
        &__txtBlock {
          padding: 25px 20px 30px;
          max-width: 100%;
        }
        &__ttl {
          font-size: 1.3rem;
        }
        &__txt {
          font-size: 1.1rem;
          margin-top: 20px;
        }
        &__btn.btn-or{
         margin-top: 25px;
         padding: 15px 20px;
         height: 50px;
         display: flex;
         justify-content: center;
         align-items: center;
         span{
          margin-left: -14px;
          &::before{
           right: 20px;
           width: 15px;
           top: 27px;
          }
          &::after{
           right: 18px;
           top: 24px;
          }
         }
        }
        + .service__contents__block {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
